<template>
  <div id="popupWrapper">
    <ejs-dialog
      ref="depositMoneyMemberBatchChangePopup"
      header="예치금회원 일괄변경"
      allowDragging="true"
      showCloseIcon="true"
      width="1180"
      height="800"
      isModal="true"
      @close="onDepositMoneyMemberBatchChangePopupClosed"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <div class="title">예치금상태</div>
                    <ul class="content">
                      <li class="item select">
                        <ejs-dropdownlist
                          v-model="searchConditions.depositMoneyStatus"
                          :dataSource="searchOptions.depositMoneyStatusOptions"
                          :allowFiltering="false"
                          :fields="commonCodeFields"
                          cssClass="lookup-condition-dropdown"
                          @change="onDepositMoneyStatusChange"
                        />
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <div class="title">일자</div>
                    <ul class="content">
                      <li class="item dateRange">
                        <input-date-range
                          v-model="inputDateRangeValue"
                          type="lookup-condition"
                        />
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <div class="title">회원명/웹 ID/핸드폰(뒷4자리)</div>
                    <ul class="content">
                      <li class="item">
                        <input-text
                          v-model="searchConditions.searchData"
                          type="lookup-condition"
                        />
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <ul class="content">
                      <li class="item">
                        <ul class="check">
                          <li>
                            <label>
                              <input
                                type="checkbox"
                                v-model="searchConditions.gradeChange"
                              />
                              <i></i>
                              <div class="label">등급변경</div>
                            </label>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <ul class="content">
                      <li class="item">
                        <ul class="check">
                          <li>
                            <label>
                              <input
                                type="checkbox"
                                v-model="searchConditions.smsSendFlag"
                              />
                              <i></i>
                              <div class="label">SMS전송여부</div>
                            </label>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
                <div class="lookup-lookup">
                  <erp-button
                      button-div="GET"
                      :ignore="isPopupOpened"
                      :is-shortcut-button="true"
                      @click.native="onDepositMoneyMemberViewButtonClicked"
                  >
                    조회
                  </erp-button>
                </div>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">예치금회원 목록</div>
                      <div class="header-caption">
                        [{{
                          numberWithCommas(
                            depositMoneyMemberBatchChangesLength
                          )
                        }}건]
                      </div>
                    </div>
                    <div class="header-right">
                      <ul class="header-button">
                        <li>
                          <erp-button
                              button-div="GET"
                              :is-icon-custom="true"
                              @click.native="onDepositMoneyMemberBatchChangeButtonClicked"
                          >
                            회원일괄변경
                          </erp-button>
                        </li>
                        <li>
                          <erp-button
                              button-div="SAVE"
                              :ignore="isPopupOpened"
                              :is-icon-custom="true"
                              :is-custom-shortcut-button="true"
                              shortcut-key="DepositMoneyMemberBatchChangePopup.shortcuts.sendSms "
                              :shortcut="{key: 'F7'}"
                            @click.native="onSmsSendButtonClicked"
                          >
                            SMS전송
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="depositMoneyMemberBatchChangeGrid"
                        :allowResizing="true"
                        :dataSource="depositMoneyMemberBatchChanges"
                        :selectionSettings="selectionSettings"
                        :allowExcelExport="true"
                        :columns="depositMoneyMemberBatchChangeGridColumn"
                        :provides="grid"
                        :isNOColumnDisplay="false"
                        :isAutoSelectRow="false"
                        :isAutoSelectCell="false"
                        @queryCellInfo="queryCellInfo"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closeDepositMoneyMemberBatchChangePopup"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <batch-sms-send-popup
      v-if="isBatchSmsSendPopupOpen"
      ref="batchSmsSendPopup"
      @popupClosed="batchSmsSendPopupClosed"
    />
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import InputText from "@/components/common/text/InputText";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import batchSmsSendPopup from "@/views/common/BatchSmsSendPopup";

import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {
  Edit,
  Aggregate,
  ExcelExport,
  Filter,
  ForeignKey,
  Group,
  Page,
  Resize,
} from "@syncfusion/ej2-vue-grids";
import { getFormattedDate } from "@/utils/date";
import {
  commonCodesGetCommonCode,
  commonCodesGetCommonCodeAttrbByCodeAndIdx,
  commonCodesGetCommonCodeAttrbNameByCodeAndIdx,
  commonCodesGetColorValue,
} from "@/utils/commonCodes";
import { numberWithCommas } from "@/utils/number";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {
  gridUtilGetTelColumnAccess,
  gridUtilGetMemberNoColumnAccess,
} from "@/utils/gridUtil";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "DepositMoneyMemberBatchChangePopup",
  components: {
    ejsGridWrapper,
    InputDateRange,
    InputText,
    batchSmsSendPopup,
    ErpButton,
  },
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  computed: {
    isPopupOpened() {
      return (
        this.isBatchSmsSendPopupOpen
      );
    },
  },
  async created() {
    this.inputDateRangeValue.from = getFormattedDate(new Date());
    this.inputDateRangeValue.to = getFormattedDate(new Date());

    const dpmnyStatusComCode = commonCodesGetCommonCode("DPMNY_STATUS", true);
    dpmnyStatusComCode.forEach((comCode) => {
      const attrb = commonCodesGetCommonCodeAttrbByCodeAndIdx(
        "DPMNY_STATUS",
        comCode.comCode,
        2
      );
      const attrbName = commonCodesGetCommonCodeAttrbNameByCodeAndIdx(
        "DPMNY_STATUS",
        comCode.comCode,
        2
      );

      if (attrb) {
        if (
          this.searchOptions.depositMoneyStatusOptions.findIndex(
            (data) => data.comCode === attrb
          ) < 0
        ) {
          this.searchOptions.depositMoneyStatusOptions.push({
            comCode: attrb,
            comName: attrbName,
          });
        }
      }
    });

    this.searchConditions.depositMoneyStatus =
      this.searchOptions.depositMoneyStatusOptions.length > 0
        ? this.searchOptions.depositMoneyStatusOptions[0].comCode
        : null;
  },
  async mounted() {},
  data() {
    return {
      isBatchSmsSendPopupOpen: false,
      inputDateRangeValue: { to: null, from: null },
      grid: [
        Edit,
        Aggregate,
        Filter,
        Resize,
        Group,
        ExcelExport,
        ForeignKey,
        Page,
      ],
      searchConditions: {
        depositMoneyStatus: null,
        searchData: null,
        smsSendFlag: false,
        gradeChange: true
      },
      searchOptions: {
        depositMoneyStatusOptions: [],
      },
      depositMoneyMemberBatchChangesLength: 0,
      depositMoneyMemberBatchChanges: [],
      commonCodeFields: { text: "comName", value: "comCode" },
      selectionSettings: {
        type: "Single",
        mode: "Both",
        enableToggle: false,
        persistSelection: false,
      },
      depositMoneyMemberBatchChangeGridColumn: [
        {
          field: "dpmnyId",
          visible: false,
          isPrimaryKey: true,
        },
        {
          field: "selectCheck",
          headerText: "",
          width: 40,
          minWidth: 16,
          editType: "booleanedit",
          type: "boolean",
          displayAsCheckBox: true,
          isSelectAllColumn: true,
          textAlign: "Center",
        },
        {
          field: "incomDate",
          headerText: "일자",
          width: 120,
          type: "string",
          isDateType: true,
          allowEditing: false,
          textAlign: "Center",
        },
        {
          field: "memberName",
          headerText: "회원명",
          width: 120,
          type: "string",
          allowEditing: false,
        },
        {
          field: "hpNo",
          headerText: "핸드폰",
          width: 120,
          type: "string",
          allowEditing: false,
          textAlign: "Center",
          valueAccessor: gridUtilGetTelColumnAccess,
        },
        {
          field: "memberNo",
          headerText: "회원번호",
          width: 120,
          type: "string",
          allowEditing: false,
          textAlign: "Center",
          valueAccessor: gridUtilGetMemberNoColumnAccess,
        },
        {
          field: "existingMemberGrade",
          headerText: "기존회원등급",
          width: 120,
          editType: "dropdownedit",
          isCommonCodeField: true,
          groupCode: "MEMBER_GRADE",
          type: "string",
          allowEditing: false,
        },
        {
          field: "changeMemberGrade",
          headerText: "변경회원등급",
          width: 120,
          editType: "dropdownedit",
          isCommonCodeField: true,
          groupCode: "MEMBER_GRADE",
          type: "string",
          allowEditing: false,
        },
        {
          field: "webId",
          headerText: "웹 ID",
          width: 120,
          type: "string",
          allowEditing: false,
        },
        {
          field: "smsSendFlag",
          headerText: "SMS전송여부",
          width: 120,
          editType: "booleanedit",
          type: "boolean",
          displayAsCheckBox: true,
          textAlign: "Center",
          allowEditing: false,
        },
        {
          field: "dpmnyStatus",
          headerText: "예치금상태",
          editType: "dropdownedit",
          isCommonCodeField: true,
          groupCode: "DPMNY_STATUS",
          type: "string",
          allowEditing: false,
          textAlign: "Center",
        },
      ],
    };
  },
  methods: {
    numberWithCommas,
    onDepositMoneyMemberBatchChangePopupClosed() {
      this.$emit("popupClosed");
    },
    closeDepositMoneyMemberBatchChangePopup() {
      this.$refs.depositMoneyMemberBatchChangePopup.hide();
    },
    queryCellInfo(args) {
      const field = args.column.field;

      if (field === "selectCheck") {
        args.cell.classList.add(this.$t("className.grid.modifyArea"));
      } else if (field === "dpmnyStatus") {
        args.cell.style.backgroundColor = commonCodesGetColorValue(
          "DPMNY_STATUS",
          args.data.dpmnyStatus
        );
      }
    },
    async onDepositMoneyMemberViewButtonClicked() {
      const args = {
        depositMoneyStatus: this.searchConditions.depositMoneyStatus,
        dateFrom: this.inputDateRangeValue.from,
        dateTo: this.inputDateRangeValue.to,
        searchData: this.searchConditions.searchData,
        smsSendFlag: this.searchConditions.smsSendFlag,
        isGradeChange: this.searchConditions.gradeChange
      };

      this.depositMoneyMemberBatchChanges = (
        await GolfErpAPI.fetchDepositMoneyMemberBatchChange(args)
      ).map(item => {
        if (item?.webId) {
          if (["KAKAO", "NAVER"].includes(item.webId?.split(":")?.[0])) {
            item.webId = item.webId?.split(":")?.[0];
          }
        }
        item.selectCheck = false;
        return item;
      });

      this.depositMoneyMemberBatchChangesLength = this.depositMoneyMemberBatchChanges.length;
    },
    async onDepositMoneyMemberBatchChangeButtonClicked() {
      const depositMoneyMembers = this.$refs.depositMoneyMemberBatchChangeGrid.getBatchCurrentViewRecords();

      let changedData = [];

      depositMoneyMembers.forEach((member) => {
        if (member.selectCheck) {
          const data = {
            id: member.membershipId,
            memberGrade: member.changeMemberGrade,
            memberId: member.memberId,
          };

          changedData.push(data);
        }
      });

      if (!(changedData.length > 0)) {
        this.errorToast("선택된 자료가 없습니다.");
        return;
      }

      if (
        !(await this.confirm(
          changedData.length + "건 회원 일괄변경 작업을 하시겠습니까?"
        ))
      ) {
        return;
      }

      await GolfErpAPI.depositMoneyMemberGradeBatchChange(changedData);

      await this.onDepositMoneyMemberViewButtonClicked();
    },
    batchSmsSendPopupClosed() {
      this.isBatchSmsSendPopupOpen = false;
    },
    onSmsSendButtonClicked() {
      const smsSendInfo = {
        smsSendType:
          this.searchConditions.depositMoneyStatus === "INCOM"
            ? "DPMNY"
            : "DPMNY_".concat(this.searchConditions.depositMoneyStatus),
        smsDiv: "DEPOSIT_MONEY",
        smsSendList: this.$refs.depositMoneyMemberBatchChangeGrid
          .getBatchCurrentViewRecords()
          .map((data) => {
            return {
              selectCheck: data.selectCheck,
              sendKey: data.incomId,
              receiverName: data.memberName,
              name:
                data.memberName +
                " " +
                commonCodesGetCommonCodeAttrbNameByCodeAndIdx(
                  "MEMBER_DIV",
                  data.memberDiv,
                  1
                ),
              contactTel: data.hpNo,
            };
          })
          .filter((data) => data.selectCheck),
      };

      if (!(smsSendInfo.smsSendList.length > 0)) {
        this.errorToast("선택된 자료가 없습니다.");
        return;
      }

      this.isBatchSmsSendPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.batchSmsSendPopup.showBatchSmsSendPopup(smsSendInfo);
      });
    },
    onDepositMoneyStatusChange(args) {
      console.log("args.===>", args);
      this.onDepositMoneyMemberViewButtonClicked();
    },
  },
};
</script>
