<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">회원구분</div>
            <ul class="content">
              <li class="item select">
                <ejs-dropdownlist
                  v-model="searchConditions.memberDiv"
                  :dataSource="searchOptions.memberDivOptions"
                  :allowFiltering="false"
                  :fields="commonCodeFields"
                  cssClass="lookup-condition-dropdown"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">예치금상태</div>
            <ul class="content">
              <li class="item select">
                <ejs-dropdownlist
                  v-model="searchConditions.dpmnyStatus"
                  :dataSource="searchOptions.dpmnyStatusOptions"
                  :allowFiltering="false"
                  :fields="commonCodeFields"
                  cssClass="lookup-condition-dropdown"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">조건검색</div>
            <ul class="content">
              <li class="item">
                <input-text
                  v-model="searchConditions.searchData"
                  placeholder="회원명/회원번호/웹 ID/핸드폰(뒷4자리)"
                  @keydown.native="formPreventEnterEvent"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title"></div>
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        v-model="searchConditions.noConfirmFlag"
                      />
                      <i></i>
                      <div class="label">미확인 자료만 조회</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :ignore="isPopupOpened"
              :is-shortcut-button="true"
              @click.native="onDepositMoneyViewClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
      <div class="lookup-right">
        <ul class="lookup-button">
          <li>
            <erp-button
                button-div="GET"
                :is-icon-custom="true"
              @click.native="onDepositMoneyMemberBatchChangePopupButtonClicked"
            >
              회원일괄변경
            </erp-button>
          </li>
        </ul>
      </div>
    </div>
    <div class="content-body">
      <ReportView ref="reportViewComponent" :isPopup="true" />
      <article
        class="body-article"
        :class="
          isDepositMoneyDetailViewOpened
            ? $t('className.reservation.reservationDetailViewOpened')
            : ''
        "
      >
        <div class="article-left">
          <!-- 아코디언 : accordion / 닫힘 : close -->
          <section class="article-section section-01">
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">예치금 목록</div>
                <div class="header-caption">
                  [{{ depositMoneyMembersCount }}건]
                </div>
              </div>
              <div class="header-right">
                <ul class="header-button">
                  <li class="add">
                    <erp-button
                        button-div="SAVE"
                        :ignore="isPopupOpened"
                        :is-icon-custom="true"
                        :is-custom-shortcut-button="true"
                        shortcut-key="DepositMoneyRegistration.shortcuts.add"
                        :shortcut="{key: 'F3'}"
                       @click.native="onDepositMoneyAddButtonClicked"
                    >
                      추가
                    </erp-button>
                  </li>
                  <li class="print">
                    <erp-button
                        button-div="PRINT"
                        :ignore="isPopupOpened"
                        :is-shortcut-button="true"
                       @click.native="print"
                    >
                      인쇄
                    </erp-button>
                  </li>
                  <li class="print">
                    <erp-button
                        button-div="FILE"
                        @click.native="onClickExcel">
                      Excel
                    </erp-button>
                  </li>
                  <li>
                    <erp-button
                        button-div="GET"
                        :is-icon-custom="true"
                      @click.native="onDepositMoneyIncomSmsSendButtonClicked"
                    >
                      신청 확인 SMS
                    </erp-button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="section-body">
              <ejs-grid-wrapper
                ref="depositMoneyMembersGrid"
                :allowResizing="true"
                :dataSource="depositMoneyMembers"
                :selectionSettings="selectionSettings"
                :allowExcelExport="true"
                :columns="depositMoneyMembersGridColumn"
                :provides="grid"
                :aggregates="depositMoneyMembersGridAggregates"
                :allowPaging="true"
                :pageSettings="depositMoneyMembersGridPageSettings"
                :isAutoSelectCell="false"
                @queryCellInfo="onDepositMoneyMembersGridQueryCellInfo"
                @rowSelecting="onDepositMoneyMembersGridRowSelected"
                @recordClick="onDepositMoneyMembersGridRecordClick"
                @actionComplete="onDepositMoneyMembersGridActionComplete"
              />
            </div>
          </section>
        </div>
        <div class="article-right">
          <!-- 아코디언 : accordion / 닫힘 : close -->
          <section class="article-section section-02">
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">예치금 정보</div>
              </div>
            </div>
            <div class="section-caption">
              <ul class="caption-button left">
                <li class="save keyColor">
                  <erp-button
                      button-div="SAVE"
                      :ignore="isPopupOpened || !isDepositMoneyDetailViewOpened"
                      :is-shortcut-button="true"
                      :is-key-color="true"
                      @click.native="onDepositMoneySaveButtonClicked"
                  >
                    저장
                  </erp-button>
                </li>
                <li>
                  <erp-button
                      button-div="GET"
                      :is-icon-custom="true"
                      @click.native="onChangeHistoryButtonClicked"
                  >
                    변경이력
                  </erp-button>
                </li>
                <li class="close">
                  <erp-button
                      button-div="CLOSE"
                      @click.native="onDepositMoneyDetailViewClosed">
                    닫기
                  </erp-button>
                </li>
              </ul>
            </div>
            <div class="section-body" v-show="isDepositMoneyDetailViewOpened">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-0201">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">예치금 목록</div>
                      <div class="header-caption">
                        [{{ depositMoneyIncomCount }}건]
                      </div>
                    </div>
                    <div class="header-right">
                      <ul class="header-button">
                        <li class="add">
                          <erp-button
                              button-div="SAVE"
                              :is-icon-custom="true"
                              @click.native="onDepositMoneyIncomAddButtonClicked"
                          >
                            추가
                          </erp-button>
                        </li>
                        <li class="delete">
                          <erp-button
                              button-div="DELETE"
                              :is-icon-custom="true"
                              @click.native="onDepositMoneyIncomDeleteButtonClicked"
                          >
                            삭제
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="depositMoneyIncomGrid"
                        :allowResizing="true"
                        :dataSource="depositMoneyIncoms"
                        :selectionSettings="selectionSettings"
                        :allowExcelExport="true"
                        :columns="depositMoneyIncomGridColumn"
                        :provides="grid"
                        :validationRules="depositMoneyIncomGridValidationRules"
                        :validationModification="false"
                        :isAutoSelectCell="false"
                        @visitEjsDropdownListEditTemplateChanged="
                          onDepositMoneyIncomGridEjsDropdownListEditTemplateChanged
                        "
                        @queryCellInfo="onDepositMoneyIncomGridQueryCellInfo"
                        @headerCellInfo="onDepositMoneyIncomGridHeaderCellInfo"
                        @actionComplete="onDepositMoneyIncomGridActionComplete"
                        @cellSaved="onDepositMoneyIncomGridCellSaved"
                      />
                    </div>
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-0202">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">차감 및 반환 목록</div>
                      <div class="header-caption">
                        [{{ depositMoneyReturnCount }}건]
                      </div>
                    </div>
                    <div class="header-right">
                      <ul class="header-button">
                        <li class="add">
                          <erp-button
                              button-div="SAVE"
                              :is-icon-custom="true"
                              @click.native="onDepositMoneyReturnAddButtonClicked"
                          >
                            추가
                          </erp-button>
                        </li>
                        <li class="delete">
                          <erp-button
                              button-div="DELETE"
                              :is-icon-custom="true"
                              @click.native="onDepositMoneyReturnDeleteButtonClicked "
                          >
                            삭제
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="depositMoneyReturnGrid"
                        :allowResizing="true"
                        :dataSource="depositMoneyReturns"
                        :selectionSettings="selectionSettings"
                        :allowExcelExport="true"
                        :columns="depositMoneyReturnGridColumn"
                        :provides="grid"
                        :validationRules="depositMoneyReturnGridValidationRules"
                        :validationModification="false"
                        :isAutoSelectCell="false"
                        @queryCellInfo="onDepositMoneyReturnGridQueryCellInfo"
                        @headerCellInfo="onDepositMoneyReturnGridHeaderCellInfo"
                        @actionComplete="onDepositMoneyReturnGridActionComplete"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </section>
        </div>
      </article>
    </div>
    <member-select-popup
      v-if="isMemberSelectPopupOpen"
      ref="memberSelectPopup"
      :position="{ x: 'center', y: 'center' }"
      :isModal="true"
      @popupClosed="memberSelectPopupClosed"
      @popupConfirmed="memberSelectPopupConfirmed"
    />
    <deposit-money-member-batch-change-popup
      v-if="isDepositMoneyMemberBatchChangePopupOpen"
      ref="depositMoneyMemberBatchChangePopup"
      :position="{ x: 'center', y: 'center' }"
      :isModal="true"
      @popupClosed="depositMoneyMemberBatchChangePopupClosed"
    />
    <history-popup
      v-if="isHistoryPopupOpen"
      ref="historyPopup"
      @popupClosed="historyPopupClose"
    />
    <sms-send-popup
      v-if="isSmsSendPopupOpen"
      ref="smsSendPopup"
      @popupClosed="smsSendPopupClosed"
    />
  </div>
</template>

<style scoped>
body .appContent .body-article .article-left,body .appContent .body-article .article-right{transition:all .3s ease-out}
body .appContent .body-article .article-left{width:100%}
body .appContent .body-article .article-right{width:1100px;margin:0 -1100px 0 0}
body .appContent .body-article.dev-reservation-detail-view-opened .article-left{width:calc(100% - 1100px)}
body .appContent .body-article.dev-reservation-detail-view-opened .article-right{margin:0}
body .appContent .article-section.section-01 .section-body{overflow:hidden;border:none}
body .appContent .article-section.section-02 .section-body{overflow-y:scroll}
body .appContent .article-section.section-02 .body-article{height:auto}
body .appContent .article-section.section-02 .body-article .article-section{height:auto}
body .appContent .article-section.section-02 .body-article .section-header{width:auto}
body .appContent .article-section.section-02 .body-article .section-body{overflow:visible;border:none}
body .appContent .article-section.section-0201 .section-body .body-grid{height:350px}
body .appContent .article-section.section-0202 .section-body .body-grid{height:209px}
</style>

<script>
import Vue from "vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import InputText from "@/components/common/text/InputText";
import memberSelectPopup from "@/views/common/MemberSelectPopup";
import depositMoneyMemberBatchChangePopup from "@/views/member-management/popup/DepositMoneyMemberBatchChangePopup";
import ReportView from "@/components/common/report/ReportView";
import HistoryPopup from "@/views/common/HistoryPopup";

import _ from "lodash";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {
  Edit,
  Aggregate,
  ExcelExport,
  Filter,
  ForeignKey,
  Group,
  Page,
  Resize,
} from "@syncfusion/ej2-vue-grids";
import { getTodayNavigationDate } from "@/utils/date";
import {
  commonCodesGetCommonCode,
  commonCodesGetComName,
  commonCodeGetComCodeDefaultValue,
  commonCodesGetStandardInfo,
  commonCodesGetCommonCodeAttrbByCodeAndIdx,
  commonCodesGetColorValue,
  commonCodesGetBizMstInfo,
  commonCodesGetCommonCodeAttrbNameByCodeAndIdx,
} from "@/utils/commonCodes";
import { numberWithCommas } from "@/utils/number";
import { formPreventEnterEvent } from "@/utils/formUtil";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import moment from "moment";
import { gridUtilGetTelColumnAccess } from "@/utils/gridUtil";
import gridVisitEjsDropdownlistEditTemplate from "@/components/common/gridTemplate/GridVisitEjsDropdownlistEditTemplate";
import { mapGetters } from "vuex";
import SmsSendPopup from "@/views/common/SmsSendPopup";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "DepositMoneyRegistration",
  components: {
    SmsSendPopup,
    ejsGridWrapper,
    InputText,
    memberSelectPopup,
    depositMoneyMemberBatchChangePopup,
    ReportView,
    ErpButton,
    HistoryPopup
  },
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  computed: {
    ...mapGetters(["username"]),
    isPopupOpened() {
      return (
        this.isMemberSelectPopupOpen ||
        this.isDepositMoneyMemberBatchChangePopupOpen ||
        this.isSmsSendPopupOpen
      );
    },
  },
  async created() {
    this.searchOptions.memberDivOptions = commonCodesGetCommonCode(
      "MEMBER_DIV"
    );
    if (
      this.searchOptions.memberDivOptions.findIndex(
        (obj) => obj.comCode === "ALL"
      ) === -1
    ) {
      this.searchOptions.memberDivOptions.unshift({
        comCode: "ALL",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }

    this.searchOptions.dpmnyStatusOptions = commonCodesGetCommonCode("DPMNY_STATUS", true);
    if (
      this.searchOptions.dpmnyStatusOptions.findIndex(
        (obj) => obj.comCode === "ALL"
      ) === -1
    ) {
      this.searchOptions.dpmnyStatusOptions.unshift({
        comCode: "ALL",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }
  },
  async mounted() {},
  data() {
    return {
      selectDepositMoneyMembersGridRecord: null,

      isSmsSendPopupOpen: false,
      isHistoryPopupOpen: false,
      isDepositMoneyMemberBatchChangePopupOpen: false,
      isDepositMoneyDetailViewOpened: false,
      depositMoneyMembersGridFirstRowSelectFlag: false,
      depositMoneyMembersGridAddRefreshFlag: false,
      gridVisitEjsDropdownlistEditTemplateEventBus: new Vue(),
      isMemberSelectPopupOpen: false,
      grid: [
        Edit,
        Aggregate,
        Filter,
        Resize,
        Group,
        ExcelExport,
        ForeignKey,
        Page,
      ],
      depositMoneyMembersCount: 0,
      depositMoneyIncomCount: 0,
      depositMoneyReturnCount: 0,
      commonCodeFields: { text: "comName", value: "comCode" },
      memberPopupType: {
        RESV: "RESV",
      },
      searchConditions: {
        memberDiv: "ALL",
        dpmnyStatus: "ALL",
        searchData: null,
        noConfirmFlag: false,
      },
      searchOptions: {
        memberDivOptions: null,
        dpmnyStatusOptions: null,
      },
      selectionSettings: {
        type: "Single",
        mode: "Both",
        enableToggle: false,
        persistSelection: false,
      },
      depositMoneyMemberInfo: {
        dpmnyId: null,
        memberNo: null,
        membershipId: null,
        memberGrade: null,
        dpmnyStatus: null,
        remarks: null,
      },
      depositMoneyMembersGridPageSettings: { pageSize: 50 },
      depositMoneyMembers: [],
      depositMoneyIncoms: [],
      depositMoneyReturns: [],
      depositMoneyReturnGridColumn: [
        {
          field: "incomId",
          visible: false,
          isPrimaryKey: true,
        },
        {
          field: "dpmnyDiv",
          headerText: "예치금구분",
          width: 110,
          editType: "dropdownedit",
          isCommonCodeField: true,
          dataSource: _.filter(
            commonCodesGetCommonCode("DPMNY_DIV", true),
            (data) => data.comCode !== "INCOM"
          ),
          type: "string",
        },
        {
          field: "dpmnyAmt",
          headerText: "차감액",
          width: 90,
          type: "string",
          isNumericType: true,
          textAlign: "Right",
        },
        {
          field: "incomDiv",
          headerText: "반환방법",
          width: 90,
          editType: "dropdownedit",
          isCommonCodeField: true,
          groupCode: "INCOM_DIV",
          type: "string",
        },
        {
          field: "bankCode",
          headerText: "은행명",
          width: 90,
          editType: "dropdownedit",
          isCommonCodeField: true,
          groupCode: "BANK_CODE",
          type: "string",
        },
        {
          field: "dpstrName",
          headerText: "예금주",
          width: 90,
          type: "string",
        },
        {
          field: "acnutNo",
          headerText: "계좌번호",
          width: 120,
          type: "string",
        },
        {
          field: "incomDate",
          headerText: "반환일자",
          width: 120,
          type: "string",
          isDateType: true,
        },
        {
          field: "confirmFlag",
          headerText: "확인",
          width: 60,
          editType: "booleanedit",
          type: "boolean",
          displayAsCheckBox: true,
          textAlign: "Center",
        },
        {
          field: "smsSendFlag",
          headerText: "SMS전송",
          width: 90,
          editType: "booleanedit",
          type: "boolean",
          displayAsCheckBox: true,
          textAlign: "Center",
        },
        {
          field: "remarks",
          headerText: "비고",
          width: 180,
          type: "string",
          maxLength: 200,
        },
        {
          field: "insertName",
          headerText: "입력자",
          type: "string",
          width: 90,
          allowEditing: false,
        },
        {
          field: "insertDt",
          headerText: "입력일시",
          type: "string",
          width: 130,
          allowEditing: false,
        },
        {
          field: "updateName",
          headerText: "수정자",
          type: "string",
          width: 90,
          allowEditing: false,
        },
        {
          field: "updateDt",
          headerText: "수정일시",
          type: "string",
          width: 130,
          allowEditing: false,
        },
      ],
      depositMoneyIncomGridColumn: [
        {
          field: "incomId",
          visible: false,
          isPrimaryKey: true,
        },
        {
          field: "dpmnyKind",
          headerText: "예치금종류",
          width: 110,
          editType: "dropdownedit",
          isCommonCodeField: true,
          editTemplate: () =>
            gridVisitEjsDropdownlistEditTemplate(
              "dpmnyKind",
              commonCodesGetCommonCode("DPMNY_KIND", true),
              this.$refs.depositMoneyIncomGrid,
              this.gridVisitEjsDropdownlistEditTemplateEventBus
            ),
          groupCode: "DPMNY_KIND",
          type: "string",
        },
        {
          field: "incomDiv",
          headerText: "입금구분",
          width: 90,
          editType: "dropdownedit",
          isCommonCodeField: true,
          groupCode: "INCOM_DIV",
          type: "string",
        },
        {
          field: "dpmnyAmt",
          headerText: "예치금액",
          width: 90,
          type: "string",
          isNumericType: true,
          textAlign: "Right",
        },
        {
          field: "confirmFlag",
          headerText: "확인",
          width: 60,
          editType: "booleanedit",
          type: "boolean",
          displayAsCheckBox: true,
          textAlign: "Center",
        },
        {
          field: "incomDate",
          headerText: "입금일자",
          width: 120,
          type: "string",
          isDateType: true,
        },
        {
          field: "bankCode",
          headerText: "입금은행",
          width: 90,
          editType: "dropdownedit",
          isCommonCodeField: true,
          groupCode: "BANK_CODE",
          type: "string",
        },
        {
          field: "dpstrName",
          headerText: "입금자",
          width: 90,
          type: "string"
        },
        {
          field: "acnutNo",
          headerText: "입금계좌번호",
          width: 120,
          type: "string",
        },
        {
          field: "remarks",
          headerText: "비고",
          width: 150,
          type: "string",
          maxLength: 200,
        },
        {
          field: "expirDate",
          headerText: "만료일자",
          width: 120,
          type: "string",
          isDateType: true,
        },
        {
          field: "insertName",
          headerText: "입력자",
          type: "string",
          width: 90,
          allowEditing: false,
        },
        {
          field: "insertDt",
          headerText: "입력일시",
          type: "string",
          width: 130,
          allowEditing: false,
        },
        {
          field: "updateName",
          headerText: "수정자",
          type: "string",
          width: 90,
          allowEditing: false,
        },
        {
          field: "updateDt",
          headerText: "수정일시",
          type: "string",
          width: 130,
          allowEditing: false,
        },
      ],
      depositMoneyMembersGridColumn: [
        {
          field: "dpmnyId",
          visible: false,
          isPrimaryKey: true,
        },
        {
          field: "memberName",
          headerText: "회원명",
          width: 90,
          allowEditing: false,
          type: "string",
        },
        {
          field: "membershipMemberGrade",
          headerText: "회원등급",
          width: 90,
          isCommonCodeField: true,
          groupCode: "MEMBER_GRADE",
          allowEditing: false,
          type: "string",
        },
        {
          field: "hpNo",
          headerText: "연락처",
          width: 100,
          textAlign: "center",
          valueAccessor: gridUtilGetTelColumnAccess,
          allowEditing: false,
          type: "string",
        },
        {
          field: "dpmnyStatus",
          headerText: "예치금상태",
          width: 100,
          textAlign: "center",
          editType: "dropdownedit",
          isCommonCodeField: true,
          groupCode: "DPMNY_STATUS",
          allowEditing: false,
          type: "string",
        },
        // {
        //   field: "memberNo",
        //   headerText: "회원번호",
        //   width: 90
        // },
        {
          field: "memberDiv",
          headerText: "회원구분",
          width: 90,
          isCommonCodeField: true,
          groupCode: "MEMBER_DIV",
          allowEditing: false,
          type: "string",
        },
        {
          field: "webId",
          headerText: "웹 ID",
          width: 90,
          allowEditing: false,
          type: "string",
        },
        {
          field: "acqsDate",
          headerText: "가입일자",
          width: 90,
          textAlign: "center",
          allowEditing: false,
          type: "string",
        },
        {
          headerText: "반환형",
          columns: [
            {
              field: "incomCompleteAmt",
              headerText: "입금완료",
              textAlign: "right",
              width: 90,
              valueAccessor: this.isZeroValueAccess,
              type: "string",
              allowEditing: false,
            },
            {
              field: "deducCompleteAmt",
              headerText: "차감완료",
              textAlign: "right",
              width: 90,
              valueAccessor: this.isZeroValueAccess,
              type: "string",
              allowEditing: false,
            },
            {
              field: "returnCompleteAmt",
              headerText: "반환완료",
              textAlign: "right",
              width: 90,
              valueAccessor: this.isZeroValueAccess,
              type: "string",
              allowEditing: false,
            },
            {
              field: "balanceAmt",
              headerText: "잔액",
              textAlign: "right",
              width: 90,
              valueAccessor: this.isZeroValueAccess,
              type: "string",
              allowEditing: false,
            },
          ],
        },
        {
          headerText: "소멸형",
          columns: [
            {
              field: "exitExpectedAmt",
              headerText: "소멸예정",
              textAlign: "right",
              width: 90,
              valueAccessor: this.isZeroValueAccess,
              type: "string",
              allowEditing: false,
            },
            {
              field: "exitCompleteAmt",
              headerText: "소멸완료",
              textAlign: "right",
              width: 90,
              valueAccessor: this.isZeroValueAccess,
              type: "string",
              allowEditing: false,
            },
          ],
        },
      ],
      depositMoneyMembersGridAggregates: [
        {
          columns: [
            {
              field: "memberName",
              aggregationType: "TotalCaption",
              customAggregate: "합계",
            },
            {
              field: "incomCompleteAmt",
              aggregationType: "TotalSum",
            },
            {
              field: "deducCompleteAmt",
              aggregationType: "TotalSum",
            },
            {
              field: "returnCompleteAmt",
              aggregationType: "TotalSum",
            },
            {
              field: "balanceAmt",
              aggregationType: "TotalSum",
            },
            {
              field: "exitExpectedAmt",
              aggregationType: "TotalSum",
            },
            {
              field: "exitCompleteAmt",
              aggregationType: "TotalSum",
            },
          ],
        },
      ],
      depositMoneyIncomGridValidationRules: {
        dpmnyAmt: {
          required: true,
          min: 1,
        },
        incomDate: {
          required: true,
          dateFormat: true,
        },
        dpstrName: {
          required: true
        }
      },
      depositMoneyReturnGridValidationRules: {
        dpmnyAmt: {
          required: true,
          min: 1,
        },
        incomDate: {
          required: true,
          dateFormat: true,
        },
      },
    };
  },
  methods: {
    numberWithCommas,
    gridUtilGetTelColumnAccess,
    formPreventEnterEvent,
    commonCodesGetComName,
    isZeroValueAccess(field, data) {
      let v = data[field];
      v = v ? (v === 0 ? "-" : numberWithCommas(v)) : "-";
      return v;
    },
    onDepositMoneyIncomGridHeaderCellInfo(args) {
      if (
        args.cell.column.field === "dpmnyKind" ||
        args.cell.column.field === "incomDiv" ||
        args.cell.column.field === "dpmnyAmt" ||
        args.cell.column.field === "incomDate" ||
        args.cell.column.field === "dpstrName"
      ) {
        // 헤더에 필수영역 표시 클래스 추가
        args.node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
    onDepositMoneyReturnGridHeaderCellInfo(args) {
      if (
        args.cell.column.field === "dpmnyDiv" ||
        args.cell.column.field === "incomDiv" ||
        args.cell.column.field === "dpmnyAmt" ||
        args.cell.column.field === "incomDate"
      ) {
        // 헤더에 필수영역 표시 클래스 추가
        args.node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
    async onDepositMoneyViewClicked() {
      // this.onDepositMoneyDetailViewClosed();

      const args = {
        memberDiv:
          this.searchConditions.memberDiv === "ALL"
            ? null
            : this.searchConditions.memberDiv,
        dpmnyStatus:
          this.searchConditions.dpmnyStatus === "ALL"
            ? null
            : this.searchConditions.dpmnyStatus,
        searchData: this.searchConditions.searchData,
        noConfirmFlag: this.searchConditions.noConfirmFlag,
      };

      this.depositMoneyMembers = (await GolfErpAPI.fetchDepositMoneyMembers(
        args
      )).map(item => {
        if (item?.webId) {
          if (["KAKAO", "NAVER"].includes(item.webId?.split(":")?.[0])) {
            item.webId = item.webId?.split(":")?.[0];
          }
        }
        return item;
      });
    },
    onDepositMoneyAddButtonClicked() {
      // if (JSON.stringify(this.depositMoneyMemberInfo))

      if (
        !this.depositMoneyMemberInfo.dpmnyId &&
        !!this.depositMoneyMemberInfo.membershipId
      ) {
        this.errorToast("입력중인 자료를 저장후 추가바랍니다.");
        return;
      }

      this.onMemberPopupOpen();
    },
    async onDepositMoneySaveButtonClicked() {
      if (!this.$refs.depositMoneyIncomGrid.validate()) {
        return;
      }
      if (!this.$refs.depositMoneyReturnGrid.validate()) {
        return;
      }

      const incomGridData = this.$refs.depositMoneyIncomGrid.getBatchCurrentViewRecords();

      if (!(incomGridData.length > 0)) {
        this.errorToast("예치금을 입력해 주시기 바랍니다.");
        return;
      }

      if (incomGridData.filter((data) => !data.confirmFlag).length > 1) {
        this.errorToast(
          "예치금 목록은 확인이 안된건이 2건 이상 될 수 없습니다."
        );
        return;
      }

      const returnGridData = this.$refs.depositMoneyReturnGrid.getBatchCurrentViewRecords();

      if (
        returnGridData.filter(
          (data) =>
            (data.dpmnyDiv === "DEDUC" || data.dpmnyDiv === "EXIT") &&
            !data.confirmFlag
        ).length > 0
      ) {
        this.errorToast(
          "차감 또는 소멸 구분은 입금상태가 체크된 상태에서만 저장이 가능합니다."
        );
        return;
      }

      const incomGridDataSortBy = _.sortBy(incomGridData, "incomId");

      let dpmnyAmt = 0;

      this.$refs.depositMoneyIncomGrid
        .getBatchCurrentViewRecords()
        .filter((data) => data.dpmnyKind === "RETURN" && data.confirmFlag)
        .map((data) => {
          dpmnyAmt = dpmnyAmt + (data.dpmnyAmt ? data.dpmnyAmt : 0);
        });

      let minusDpmnyAmt = 0;

      this.$refs.depositMoneyReturnGrid
        .getBatchCurrentViewRecords()
        .filter((data) => data.dpmnyKind !== "EXIT" && data.confirmFlag)
        .map((data) => {
          minusDpmnyAmt = minusDpmnyAmt + (data.dpmnyAmt ? data.dpmnyAmt : 0);
        });

      if (dpmnyAmt < minusDpmnyAmt) {
        this.errorToast(
          "저장하려는 데이터의 총 예탁금액(" +
            numberWithCommas(dpmnyAmt) +
            ")이 총 차감 및 반환금액(" +
            numberWithCommas(minusDpmnyAmt) +
            ")보다 작습니다."
        );
        return;
      }

      const {
        addedRecords: incomGridAddedRecords,
        changedRecords: incomGridChangedRecords,
      } = this.$refs.depositMoneyIncomGrid.getBatchChanges();

      const {
        addedRecords: returnGridAddedRecords,
        changedRecords: returnGridChangedRecords,
      } = this.$refs.depositMoneyReturnGrid.getBatchChanges();

      let editDepositMoneyIncoms = [];

      if (incomGridAddedRecords.length > 0) {
        incomGridAddedRecords.forEach((data) => {
          const incomData = {
            incomId: null,
            dpmnyId: this.depositMoneyMemberInfo.dpmnyId ? this.depositMoneyMemberInfo.dpmnyId : null,
            dpmnyKind: data.dpmnyKind,
            dpmnyDiv: "INCOM",
            incomDate: data.incomDate,
            expirDate: data.expirDate,
            incomDiv: data.incomDiv,
            dpmnyAmt: data.dpmnyAmt,
            confirmFlag: data.confirmFlag,
            bankCode: data.bankCode,
            dpstrName: data.dpstrName,
            acnutNo: data.acnutNo,
            remarks: data.remarks,
            smsSendFlag: false,
            smsSendCnt: 0,
            accSlipFlag: false,
          };

          editDepositMoneyIncoms.push(incomData);
        });
      }

      if (incomGridChangedRecords.length > 0) {
        incomGridChangedRecords.forEach((data) => {
          const incomData = {
            incomId: data.incomId,
            dpmnyId: data.dpmnyId,
            dpmnyKind: data.dpmnyKind,
            dpmnyDiv: data.dpmnyDiv,
            incomDate: data.incomDate,
            expirDate: data.expirDate,
            incomDiv: data.incomDiv,
            dpmnyAmt: data.dpmnyAmt,
            confirmFlag: data.confirmFlag,
            bankCode: data.bankCode,
            dpstrName: data.dpstrName,
            acnutNo: data.acnutNo,
            remarks: data.remarks,
          };

          editDepositMoneyIncoms.push(incomData);
        });
      }

      if (returnGridAddedRecords.length > 0) {
        returnGridAddedRecords.forEach((data) => {
          const returnData = {
            incomId: null,
            dpmnyId: this.depositMoneyMemberInfo.dpmnyId ? this.depositMoneyMemberInfo.dpmnyId : null,
            dpmnyKind: incomGridDataSortBy[0].dpmnyKind,
            dpmnyDiv: data.dpmnyDiv,
            incomDate: data.incomDate,
            expirDate: null,
            incomDiv: data.incomDiv,
            dpmnyAmt: data.dpmnyAmt,
            confirmFlag: data.confirmFlag,
            bankCode: data.bankCode,
            dpstrName: data.dpstrName,
            acnutNo: data.acnutNo,
            remarks: data.remarks,
            smsSendFlag: data.smsSendFlag,
            smsSendCnt: 0,
            accSlipFlag: false,
          };

          editDepositMoneyIncoms.push(returnData);
        });
      }

      if (returnGridChangedRecords.length > 0) {
        returnGridChangedRecords.forEach((data) => {
          const returnData = {
            incomId: data.incomId,
            dpmnyId: data.dpmnyId,
            dpmnyKind: data.dpmnyKind,
            dpmnyDiv: data.dpmnyDiv,
            incomDate: data.incomDate,
            expirDate: null,
            incomDiv: data.incomDiv,
            dpmnyAmt: data.dpmnyAmt,
            confirmFlag: data.confirmFlag,
            bankCode: data.bankCode,
            dpstrName: data.dpstrName,
            acnutNo: data.acnutNo,
            remarks: data.remarks,
            smsSendFlag: data.smsSendFlag,
          };

          editDepositMoneyIncoms.push(returnData);
        });
      }

      const dpmnyStatus =
        incomGridDataSortBy[0].dpmnyKind === "EXIT" ? "A1" : "B1";

      const saveData = {
        dpmnyId: this.depositMoneyMemberInfo.dpmnyId ? this.depositMoneyMemberInfo.dpmnyId : null,
        membershipId: this.depositMoneyMemberInfo.membershipId,
        memberGrade: this.depositMoneyMemberInfo.memberGrade,
        dpmnyStatus: this.depositMoneyMemberInfo.dpmnyId ? null : dpmnyStatus,
        remarks: null,
        depositMoneyIncoms: editDepositMoneyIncoms,
      };

      try {
        const dpmnyId = await GolfErpAPI.depositMoneySave(saveData);

        if (!saveData.dpmnyId) {
          saveData.dpmnyId = dpmnyId;
          this.depositMoneyMemberInfo.dpmnyId = dpmnyId;
          // this.onDepositMoneyDetailViewClosed();
          this.depositMoneyMembersGridAddRefreshFlag = true;
          await this.onDepositMoneyViewClicked();
          return;
        }

        await this.fetchDepositMoneyMember(saveData.dpmnyId, true);
      } catch (e) {
        if (e.message === "EXIST_DEPOSIT_MONEY_MEMBERSHIP_DATA") {
          this.searchConditions.searchData = this.depositMoneyMemberInfo.memberNo;
          this.depositMoneyMembersGridFirstRowSelectFlag = true;
          this.depositMoneyMemberInfo.membershipId = null; // onDepositMoneyMembersGridRecordClick Method의 입력체크를 피하기 위함.
          await this.onDepositMoneyViewClicked();
        }
      }
    },
    init() {
      this.depositMoneyMemberInfo = {
        dpmnyId: null,
        memberNo: null,
        membershipId: null,
        memberGrade: null,
        dpmnyStatus: null,
        remarks: null,
      };
      this.depositMoneyIncoms = [];
      this.depositMoneyReturns = [];

      // this.$refs.depositMoneyMembersGrid.refresh();
    },
    async onChangeHistoryButtonClicked() {
      const { dpmnyId } = this.selectDepositMoneyMembersGridRecord;

      if (!dpmnyId) {
        this.errorToast("예치금 정보가 없습니다");
        return;
      }

      const rawHistories = await GolfErpAPI.fetchDepositMoneyHistory({
        dpmnyId
      });

      this.isHistoryPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.historyPopup.show({
          rawHistories,
          fields: {
            DepositMoneyIncom: {
              __name__: "예치금 입금 정보",
              dpmnyKind: {
                name: "예치금종류",
                type: "commonCode",
                groupCode: "DPMNY_KIND",
                methods: ["create", "update", "delete"],
              },
              dpmnyDiv: {
                name: "예치금구분",
                type: "commonCode",
                groupCode: "DPMNY_DIV",
                methods: ["create", "update", "delete"],
              },
              incomDate: {
                name: "입금일자",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              expirDate: {
                name: "만료일자",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              incomDiv: {
                name: "입금구분",
                type: "commonCode",
                groupCode: "INCOM_DIV",
                methods: ["create", "update", "delete"],
              },
              dpmnyAmt: {
                name: "예치금액",
                type: "number",
                methods: ["create", "update", "delete"],
              },
              confirmFlag: {
                name: "확인여부",
                type: "boolean",
                methods: ["create", "update", "delete"],
              },
              bankCode: {
                name: "은행",
                type: "commonCode",
                groupCode: "BANK_CODE",
                methods: ["create", "update", "delete"],
              },
              dpstrName: {
                name: "예금주명",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              acnutNo: {
                name: "계좌번호",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              remarks: {
                name: "비고",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              smsSendFlag: {
                name: "SMS전송여부",
                type: "boolean",
                methods: ["create", "update", "delete"],
              },
              smsSendCnt: {
                name: "SMS전송횟수",
                type: "number",
                methods: ["create", "update", "delete"],
              },
              smsSendDt: {
                name: "SMS전송일시",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              accSlipFlag: {
                name: "회계전표여부",
                type: "boolean",
                methods: ["create", "update", "delete"],
              },
              accSlipNo: {
                name: "회계전표번호",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              delFlag: {
                name: "삭제여부",
                type: "boolean",
                methods: ["create", "update", "delete"],
              },
            },
            DepositMoneyReturn: {
              __name__: "예치금 반환 정보",
              dpmnyKind: {
                name: "예치금종류",
                type: "commonCode",
                groupCode: "DPMNY_KIND",
                methods: ["create", "update", "delete"],
              },
              dpmnyDiv: {
                name: "예치금구분",
                type: "commonCode",
                groupCode: "DPMNY_DIV",
                methods: ["create", "update", "delete"],
              },
              incomDate: {
                name: "입금일자",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              expirDate: {
                name: "만료일자",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              incomDiv: {
                name: "입금구분",
                type: "commonCode",
                groupCode: "INCOM_DIV",
                methods: ["create", "update", "delete"],
              },
              dpmnyAmt: {
                name: "예치금액",
                type: "number",
                methods: ["create", "update", "delete"],
              },
              confirmFlag: {
                name: "확인여부",
                type: "boolean",
                methods: ["create", "update", "delete"],
              },
              bankCode: {
                name: "은행",
                type: "commonCode",
                groupCode: "BANK_CODE",
                methods: ["create", "update", "delete"],
              },
              dpstrName: {
                name: "예금주명",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              acnutNo: {
                name: "계좌번호",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              remarks: {
                name: "비고",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              smsSendFlag: {
                name: "SMS전송여부",
                type: "boolean",
                methods: ["create", "update", "delete"],
              },
              smsSendCnt: {
                name: "SMS전송횟수",
                type: "number",
                methods: ["create", "update", "delete"],
              },
              smsSendDt: {
                name: "SMS전송일시",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              accSlipFlag: {
                name: "회계전표여부",
                type: "boolean",
                methods: ["create", "update", "delete"],
              },
              accSlipNo: {
                name: "회계전표번호",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              delFlag: {
                name: "삭제여부",
                type: "boolean",
                methods: ["create", "update", "delete"],
              },
            },
            DepositMoneyDeduc: {
              __name__: "예치금 차감 정보",
              dpmnyKind: {
                name: "예치금종류",
                type: "commonCode",
                groupCode: "DPMNY_KIND",
                methods: ["create", "update", "delete"],
              },
              dpmnyDiv: {
                name: "예치금구분",
                type: "commonCode",
                groupCode: "DPMNY_DIV",
                methods: ["create", "update", "delete"],
              },
              incomDate: {
                name: "입금일자",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              expirDate: {
                name: "만료일자",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              incomDiv: {
                name: "입금구분",
                type: "commonCode",
                groupCode: "INCOM_DIV",
                methods: ["create", "update", "delete"],
              },
              dpmnyAmt: {
                name: "예치금액",
                type: "number",
                methods: ["create", "update", "delete"],
              },
              confirmFlag: {
                name: "확인여부",
                type: "boolean",
                methods: ["create", "update", "delete"],
              },
              bankCode: {
                name: "은행",
                type: "commonCode",
                groupCode: "BANK_CODE",
                methods: ["create", "update", "delete"],
              },
              dpstrName: {
                name: "예금주명",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              acnutNo: {
                name: "계좌번호",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              remarks: {
                name: "비고",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              smsSendFlag: {
                name: "SMS전송여부",
                type: "boolean",
                methods: ["create", "update", "delete"],
              },
              smsSendCnt: {
                name: "SMS전송횟수",
                type: "number",
                methods: ["create", "update", "delete"],
              },
              smsSendDt: {
                name: "SMS전송일시",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              accSlipFlag: {
                name: "회계전표여부",
                type: "boolean",
                methods: ["create", "update", "delete"],
              },
              accSlipNo: {
                name: "회계전표번호",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              delFlag: {
                name: "삭제여부",
                type: "boolean",
                methods: ["create", "update", "delete"],
              },
            },
          }
        });
      });
    },
    onDepositMoneyDetailViewClosed() {
      this.isDepositMoneyDetailViewOpened = false;

      this.init();
    },
    onMemberPopupOpen: function (
      isOpenedWithButton,
      popupType,
      memberNameNo,
      timeInfoIdx,
      selectedIdx
    ) {
      this.isMemberSelectPopupOpen = true;

      let memberData = {};
      memberData.memberNameNo = memberNameNo;
      memberData.isOpenedWithButton = isOpenedWithButton;
      memberData.popupType = popupType;
      memberData.nomDataViewFlag = false;
      if (timeInfoIdx !== null && timeInfoIdx !== undefined) {
        memberData.timeInfoIdx = timeInfoIdx;
      }
      if (selectedIdx !== null && selectedIdx !== undefined) {
        memberData.selectedIdx = selectedIdx;
      }
      this.$nextTick(() => {
        this.$refs.memberSelectPopup.showPopup(memberData);
      });
    },
    memberSelectPopupClosed() {
      this.isMemberSelectPopupOpen = false;

      this.setNonMemberInfos();
    },
    memberSelectPopupConfirmed(data) {
      this.isMemberSelectPopupOpen = false;

      if (data.selectedRowData) {
        if (!data.selectedRowData.membershipId) {
          this.setNonMemberInfos();
        } else {
          this.setMemberInfos(data.selectedRowData);
        }
      }
    },
    async setMemberInfos(memberInfo) {
      this.init();

      const depositMoneyMemberCount = await GolfErpAPI.fetchDepositMoneyMemberCount(
        memberInfo.membershipId
      );

      if (depositMoneyMemberCount > 0) {
        this.errorToast(
          "이미 예치금 데이터가 있는 회원입니다. 신규추가를 할 수 없습니다.<br>해당 회원정보로 이동합니다."
        );
        this.searchConditions.searchData = memberInfo.memberNo;
        this.depositMoneyMembersGridFirstRowSelectFlag = true;
        await this.onDepositMoneyViewClicked();
        return;
      }

      const addData = {
        memberName: memberInfo.memberName,
        hpNo: memberInfo.hpNo,
        webId: memberInfo.webId,
        membershipId: memberInfo.membershipId,
        memberGrade: memberInfo.memberGrade,
        memberDiv: memberInfo.memberDiv,
        acqsDate: memberInfo.acqsDate,
      };

      this.$refs.depositMoneyMembersGrid.addRecord(addData);

      this.depositMoneyMemberInfo = {
        dpmnyId: null,
        memberNo: memberInfo.memberNo,
        membershipId: memberInfo.membershipId,
        memberGrade: memberInfo.memberGrade,
        dpmnyStatus: null,
        remarks: null,
      };

      this.isDepositMoneyDetailViewOpened = true;
    },
    setNonMemberInfos() {
      this.depositMoneyMemberInfo = {
        dpmnyId: null,
        memberNo: null,
        membershipId: null,
        memberGrade: null,
        dpmnyStatus: null,
        remarks: null,
      };
    },
    async onDepositMoneyIncomAddButtonClicked() {
      const {
        addedRecords,
      } = this.$refs.depositMoneyIncomGrid.getBatchChanges();

      if (addedRecords.length > 0) {
        this.errorToast("입력중인 자료를 저장후 추가바랍니다.");
        return;
      }

      const tsConfAccounts = commonCodesGetStandardInfo("tsConfAccounts") ?
         commonCodesGetStandardInfo("tsConfAccounts").filter(
            (data) => data.accountUseDiv === "DPMNY"
          ).length > 0
          ? commonCodesGetStandardInfo("tsConfAccounts").filter(
              (data) => data.accountUseDiv === "DPMNY"
            )[0]
          : null
        : null;

      const incomDate = await getTodayNavigationDate("YYYY-MM-DD");

      const expirDateAddMonth = commonCodesGetCommonCodeAttrbByCodeAndIdx(
        "DPMNY_KIND",
        commonCodeGetComCodeDefaultValue("DPMNY_KIND"),
        2
      );

      const expirDate = moment(incomDate)
        .add(expirDateAddMonth, "M")
        .format("YYYY-MM-DD");

      const memberName = this.$refs.depositMoneyMembersGrid.getSelectedRecords()[0]?.memberName;

      const addData = {
        dpmnyKind: commonCodeGetComCodeDefaultValue("DPMNY_KIND"),
        dpmnyDiv: commonCodeGetComCodeDefaultValue("DPMNY_DIV"),
        incomDiv: "TRANS",
        incomDate: incomDate,
        bankCode:
          !!tsConfAccounts && !!tsConfAccounts.bankCode
            ? tsConfAccounts.bankCode
            : commonCodeGetComCodeDefaultValue("BANK_CODE"),
        acnutNo:
          !!tsConfAccounts && !!tsConfAccounts.acnutNo
            ? tsConfAccounts.acnutNo
            : null,
        confirmFlag: true,
        dpmnyAmt: commonCodesGetCommonCodeAttrbByCodeAndIdx(
          "DPMNY_KIND",
          "RETURN",
          1
        ),
        expirDate: expirDate,
        dpstrName: memberName
      };

      this.$refs.depositMoneyIncomGrid.addRecord(addData);
    },
    async onDepositMoneyIncomDeleteButtonClicked() {
      if (
        this.commonMixinIsButtonDisableByAuth("depositMoneyRegistrationDel")
      ) {
        return;
      }

      const selectedRecords = this.$refs.depositMoneyIncomGrid.getSelectedRecords();

      if (!(selectedRecords.length > 0)) {
        return;
      }

      if (!(await this.confirm("선택하신 예치금 데이터를 삭제하시겠습니까?"))) {
        return;
      }

      this.$refs.depositMoneyIncomGrid.deleteRecord();

      let dpmnyAmt = 0;

      this.$refs.depositMoneyIncomGrid
        .getBatchCurrentViewRecords()
        .filter((data) => data.dpmnyKind === "RETURN" && data.confirmFlag)
        .map((data) => {
          dpmnyAmt = dpmnyAmt + (data.dpmnyAmt ? data.dpmnyAmt : 0);
        });

      let minusDpmnyAmt = 0;

      this.$refs.depositMoneyReturnGrid
        .getBatchCurrentViewRecords()
        .filter((data) => data.dpmnyKind !== "EXIT" && data.confirmFlag)
        .map((data) => {
          minusDpmnyAmt = minusDpmnyAmt + (data.dpmnyAmt ? data.dpmnyAmt : 0);
        });

      if (dpmnyAmt < minusDpmnyAmt) {
        this.errorToast(
          "저장하려는 데이터의 총 예탁금액(" +
            numberWithCommas(dpmnyAmt) +
            ")이 총 차감 및 반환금액(" +
            numberWithCommas(minusDpmnyAmt) +
            ")보다 작습니다."
        );
        this.$refs.depositMoneyIncomGrid.refresh();
        return;
      }

      if (selectedRecords[0].incomId) {
        await GolfErpAPI.depositMoneyDelete(
          selectedRecords[0].incomId,
          selectedRecords[0].dpmnyId
        );

        const depositMoneyIncomCount = await GolfErpAPI.fetchDepositMoneyIncomCount(
          selectedRecords[0].dpmnyId
        );

        if (!(depositMoneyIncomCount > 0)) {
          this.$refs.depositMoneyMembersGrid.clearSelection();
          await this.onDepositMoneyViewClicked();
        } else {
          await this.fetchDepositMoneyMember(selectedRecords[0].dpmnyId, true);
        }
      }
    },
    async onDepositMoneyReturnAddButtonClicked() {
      const {
        addedRecords,
      } = this.$refs.depositMoneyReturnGrid.getBatchChanges();

      if (addedRecords.length > 0) {
        this.errorToast("입력중인 자료를 저장후 추가바랍니다.");
        return;
      }

      let dpmnyAmt = 0;

      this.$refs.depositMoneyIncomGrid
        .getCurrentViewRecords()
        .filter((data) => data.dpmnyKind === "RETURN" && data.confirmFlag)
        .map((data) => {
          dpmnyAmt = dpmnyAmt + (data.dpmnyAmt ? data.dpmnyAmt : 0);
        });

      let minusDpmnyAmt = 0;

      this.$refs.depositMoneyReturnGrid
        .getCurrentViewRecords()
        .filter((data) => data.dpmnyKind !== "EXIT" && data.confirmFlag)
        .map((data) => {
          minusDpmnyAmt = minusDpmnyAmt + (data.dpmnyAmt ? data.dpmnyAmt : 0);
        });

      if (!(dpmnyAmt - minusDpmnyAmt > 0)) {
        this.errorToast(
          "차감 및 반환할 잔액이 없습니다.<br>입력중인 예치금 내역이 있다면<br>저장후 추가버튼을 입력해주시기<br>바랍니다."
        );
        return;
      }

      const incomDate = await getTodayNavigationDate("YYYY-MM-DD");

      const addData = {
        dpmnyDiv: "RETURN",
        incomDiv: "TRANS",
        incomDate: incomDate,
        confirmFlag: false,
        dpmnyAmt: dpmnyAmt - minusDpmnyAmt,
        smsSendFlag: false,
      };

      this.$refs.depositMoneyReturnGrid.addRecord(addData);
    },
    async onDepositMoneyReturnDeleteButtonClicked() {
      if (
        this.commonMixinIsButtonDisableByAuth("depositMoneyRegistrationDel")
      ) {
        return;
      }

      const selectedRecords = this.$refs.depositMoneyReturnGrid.getSelectedRecords();

      if (!(selectedRecords.length > 0)) {
        return;
      }

      if (
        !(await this.confirm(
          "선택하신 차감 및 반환 데이터를 삭제하시겠습니까?"
        ))
      ) {
        return;
      }

      this.$refs.depositMoneyReturnGrid.deleteRecord();

      if (selectedRecords[0].incomId) {
        await GolfErpAPI.depositMoneyDelete(
          selectedRecords[0].incomId,
          selectedRecords[0].dpmnyId
        );

        const depositMoneyIncomCount = await GolfErpAPI.fetchDepositMoneyIncomCount(
          selectedRecords[0].dpmnyId
        );

        if (!(depositMoneyIncomCount > 0)) {
          this.$refs.depositMoneyMembersGrid.clearSelection();
          await this.onDepositMoneyViewClicked();
        } else {
          await this.fetchDepositMoneyMember(selectedRecords[0].dpmnyId, true);
        }
      }
    },
    onDepositMoneyIncomGridEjsDropdownListEditTemplateChanged(args) {
      if (!args) {
        throw new Error(
          "invalid args in onVisitEjsDropdownListEditTemplateChanged"
        );
      }

      if (args.columnName === "dpmnyKind") {
        const dpmnyAmt = commonCodesGetCommonCodeAttrbByCodeAndIdx(
          "DPMNY_KIND",
          args.value,
          1
        );

        this.$refs.depositMoneyIncomGrid.updateCell(
          args.rowIndex,
          "dpmnyAmt",
          dpmnyAmt
        );

        const expirDateAddMonth = commonCodesGetCommonCodeAttrbByCodeAndIdx(
          "DPMNY_KIND",
          args.value,
          2
        );

        const currentViewRecords = this.$refs.depositMoneyIncomGrid.getBatchCurrentViewRecords();

        if (!moment(currentViewRecords[args.rowIndex].incomDate).isValid()) {
          return;
        }

        const expirDate = moment(currentViewRecords[args.rowIndex].incomDate)
          .add(expirDateAddMonth, "M")
          .format("YYYY-MM-DD");

        this.$refs.depositMoneyIncomGrid.updateCell(
          args.rowIndex,
          "expirDate",
          expirDate
        );
      }
    },
    onDepositMoneyIncomGridCellSaved(args) {
      if (args.columnName === "incomDate") {
        const expirDateAddMonth = commonCodesGetCommonCodeAttrbByCodeAndIdx(
          "DPMNY_KIND",
          args.rowData.dpmnyKind,
          2
        );

        if (!moment(args.value).isValid()) {
          return;
        }

        const expirDate = moment(args.value)
          .add(expirDateAddMonth, "M")
          .format("YYYY-MM-DD");

        const rowIndexes = this.$refs.depositMoneyIncomGrid.getSelectedRowIndexes();

        this.$refs.depositMoneyIncomGrid.updateCell(
          rowIndexes[0],
          "expirDate",
          expirDate
        );
      }
    },
    async onDepositMoneyMembersGridRowSelected() {
      // if (
      //   !this.depositMoneyMemberInfo.dpmnyId &&
      //   !!this.depositMoneyMemberInfo.membershipId &&
      //   !!args.data.dpmnyId
      // ) {
      //   this.errorToast("입력중인 자료를 저장후 조회바랍니다.");
      //   return;
      // }
      //
      // const dpmnyId = args.data.dpmnyId;
      //
      // if (!dpmnyId) {
      //   return;
      // }
      //
      // await this.fetchDepositMoneyMember(dpmnyId);
      //
      // this.isDepositMoneyDetailViewOpened = true;
    },
    async onDepositMoneyMembersGridRecordClick(args) {
      console.log("recordClick.args.===>", args);
      if (
        !this.depositMoneyMemberInfo.dpmnyId &&
        !!this.depositMoneyMemberInfo.membershipId &&
        !!args.rowData.dpmnyId
      ) {
        this.errorToast("입력중인 자료를 저장후 조회바랍니다.");
        return;
      }

      this.selectDepositMoneyMembersGridRecord = args.rowData;

      const dpmnyId = args.rowData.dpmnyId;

      if (!dpmnyId) {
        return;
      }

      await this.fetchDepositMoneyMember(dpmnyId);

      this.isDepositMoneyDetailViewOpened = true;
    },
    async fetchDepositMoneyMember(dpmnyId, dpmnyStatusSetting = false) {
      const data = await GolfErpAPI.fetchDepositMoneyMember(dpmnyId);

      if (!data || !data.dpmnyId) {
        this.errorToast(
          "자료가 변경되었습니다. 자료를 조회 후 다시 작업 바랍니다."
        );
        return;
      }

      this.depositMoneyMemberInfo = JSON.parse(JSON.stringify(data));

      if (!data.depositMoneyIncoms || !(data.depositMoneyIncoms.length > 0)) {
        return;
      }

      const depositMoneyIncoms = data.depositMoneyIncoms;

      this.depositMoneyIncoms = depositMoneyIncoms.filter(
        (data) => data.dpmnyDiv === "INCOM"
      );
      this.depositMoneyReturns = depositMoneyIncoms.filter(
        (data) => data.dpmnyDiv !== "INCOM"
      );

      if (dpmnyStatusSetting) {
        if (
          this.selectDepositMoneyMembersGridRecord &&
          data.dpmnyId === this.selectDepositMoneyMembersGridRecord.dpmnyId &&
          this.selectDepositMoneyMembersGridRecord.memberNo
        ) {
          const { memberNo } = this.selectDepositMoneyMembersGridRecord;
          this.searchConditions.searchData = `${memberNo}`;
          await this.onDepositMoneyViewClicked();
        } else {
          const getRowIndex = this.$refs.depositMoneyMembersGrid.getRowIndexByPrimaryKey(
            data.dpmnyId
          );
          console.log("getRowIndex.===>", getRowIndex);
          if (getRowIndex >= 0) {
            this.$refs.depositMoneyMembersGrid.updateCell(
              getRowIndex,
              "dpmnyStatus",
              data.dpmnyStatus
            );
            this.$refs.depositMoneyMembersGrid.batchSave();
          }
        }
      }
    },
    onDepositMoneyMembersGridActionComplete(args) {
      this.depositMoneyMembersCount = numberWithCommas(
        this.$refs.depositMoneyMembersGrid?.getGridBatchCount() || 0
      );

      if (
        args.requestType === "refresh" &&
        this.depositMoneyMembersGridFirstRowSelectFlag
      ) {
        this.$refs.depositMoneyMembersGrid.selectRow(0);
        const selectedRecords = this.$refs.depositMoneyMembersGrid.getSelectedRecords();
        this.depositMoneyMembersGridFirstRowSelectFlag = false;

        if (selectedRecords.length > 0) {
          const recordClickArgs = {
            rowData: {
              dpmnyId: selectedRecords[0].dpmnyId,
            },
          };
          this.onDepositMoneyMembersGridRecordClick(recordClickArgs);
        }
      } else if (
        args.requestType === "refresh" &&
        this.depositMoneyMembersGridAddRefreshFlag
      ) {
        this.depositMoneyMembersGridAddRefreshFlag = false;

        const getRowIndex = this.$refs.depositMoneyMembersGrid.getRowIndexByPrimaryKey(
          this.depositMoneyMemberInfo.dpmnyId
        );

        if (getRowIndex >= 0) {
          this.$refs.depositMoneyMembersGrid.selectRow(getRowIndex);
          const selectedRecords = this.$refs.depositMoneyMembersGrid.getSelectedRecords();

          if (selectedRecords.length > 0) {
            const recordClickArgs = {
              rowData: {
                dpmnyId: selectedRecords[0].dpmnyId,
              },
            };
            this.onDepositMoneyMembersGridRecordClick(recordClickArgs);
          }
        } else {
          this.onDepositMoneyDetailViewClosed();
          this.$refs.depositMoneyMembersGrid.clearSelection();
        }
      } else if (args.requestType === "refresh") {
        const selectedRecords = this.$refs.depositMoneyMembersGrid.getSelectedRecords();

        if (!(selectedRecords.length > 0)) {
          this.onDepositMoneyDetailViewClosed();
        }
      } else if (args.requestType === "batchsave") {
        if (this.depositMoneyMemberInfo.dpmnyId) {
          const getRowIndex = this.$refs.depositMoneyMembersGrid.getRowIndexByPrimaryKey(
            this.depositMoneyMemberInfo.dpmnyId
          );
          if (getRowIndex >= 0) {
            this.$refs.depositMoneyMembersGrid.selectRow(getRowIndex);
          }
        }
      }
    },
    onDepositMoneyIncomGridActionComplete() {
      this.depositMoneyIncomCount = numberWithCommas(
        this.$refs.depositMoneyIncomGrid?.getGridBatchCount() || 0
      );
    },
    onDepositMoneyReturnGridActionComplete() {
      this.depositMoneyReturnCount = numberWithCommas(
        this.$refs.depositMoneyReturnGrid?.getGridBatchCount() || 0
      );
    },
    onInitButtonClicked() {
      this.searchConditions.searchData = null;
      this.searchConditions.noConfirmFlag = false;
    },
    depositMoneyMemberBatchChangePopupClosed() {
      this.isDepositMoneyMemberBatchChangePopupOpen = false;
    },
    onDepositMoneyMemberBatchChangePopupButtonClicked() {
      this.isDepositMoneyMemberBatchChangePopupOpen = true;
    },
    onDepositMoneyMembersGridQueryCellInfo(args) {
      const field = args.column.field;

      if (field === "dpmnyStatus") {
        args.cell.style.backgroundColor = commonCodesGetColorValue(
          "DPMNY_STATUS",
          args.data.dpmnyStatus
        );
      }
    },
    onDepositMoneyIncomGridQueryCellInfo(args) {
      const field = args.column.field;

      if (field === "confirmFlag") {
        args.cell.classList.add(this.$t("className.grid.modifyArea"));
      }
    },
    onDepositMoneyReturnGridQueryCellInfo(args) {
      const field = args.column.field;

      if (field === "confirmFlag") {
        args.cell.classList.add(this.$t("className.grid.modifyArea"));
      }
    },
    async print() {
      const options = [
        {
          field: "membershipMemberGrade",
          comCode: "MEMBER_GRADE",
          format: "comCode",
        },
        {
          field: "dpmnyStatus",
          comCode: "DPMNY_STATUS",
          format: "comCode",
        },
        {
          field: "memberDiv",
          comCode: "MEMBER_DIV",
          format: "comCode",
        },
        {
          field: "hpNo",
          format: "tell",
        },
      ];

      const result = this.$refs.depositMoneyMembersGrid.getGridBatchData(
        options
      );
      if (result.length < 1) {
        return this.errorToast(this.$t("report.popupMessage.noData"));
      }

      const searchOptionsList = [
        {
          key: "회원구분",
          value: commonCodesGetComName(
            "MEMBER_DIV",
            this.searchConditions.memberDiv
          ),
        },
        {
          key: "예치금상태",
          value: commonCodesGetComName(
            "DPMNY_STATUS",
            this.searchConditions.dpmnyStatus
          ),
        },
        {
          key: "미확인 자료만 조회",
          value: this.searchConditions.noConfirmFlag ? "예" : null,
        },
      ];

      const searchOptions = searchOptionsList
        .filter((item) => !!item.value)
        .map((item) => item.key + ": " + item.value)
        .join(", ");

      const reportData = {
        reportJson: {
          jsonData: result,
          username: this.username,
          uniqueCode: this.$options.name,
          searchOptions,
        },
      };

      this.$refs.reportViewComponent.postReport(reportData, this.$options.name);
    },
    async onClickExcel() {
      this.$refs.depositMoneyMembersGrid.excelExport();
    },
    historyPopupClose: function () {
      this.isHistoryPopupOpen = false; // 닫히면 DOM에서 없애버림. 새로 띄울 때 초기화시키기
    },
    smsSendPopupClosed() {
      this.isSmsSendPopupOpen = false;
    },
    async makeSmsSendMessage(data) {
      console.log('data.===>', data);
      const smsInfo = (await GolfErpAPI.fetchAllSmsWords()).filter(item => item.smsSendType === "DPMNY_REG");

      if (!(smsInfo.length > 0)) {
        throw new Error("SMS 문구가 존재하지 않습니다");
      }

      const tsConfAccounts = commonCodesGetStandardInfo("tsConfAccounts").find(item => item.accountUseDiv === "DPMNY");

      if (!tsConfAccounts) {
        throw new Error("계좌정보가 존재하지 않습니다");
      }

      const bizInfo = commonCodesGetBizMstInfo();

      let smsWords = smsInfo[0].smsWords;
      const aditWords = (smsInfo[0].aditWords ? smsInfo[0].aditWords : "");

      smsWords = smsWords.replaceAll("[HEADER_MEMO]$", commonCodesGetStandardInfo("bizNameLocation") === "UP" ? commonCodesGetStandardInfo("bizName") : "");
      smsWords = smsWords.replaceAll("[NAME]$", data.memberName + " " + (commonCodesGetCommonCodeAttrbNameByCodeAndIdx("MEMBER_DIV", data.memberDiv, 1) || ""));
      smsWords = smsWords.replaceAll("[DPSTR_NAME]$", bizInfo.bizName);
      smsWords = smsWords.replaceAll("[ACNUT_NO]$", tsConfAccounts.acnutNo);
      smsWords = smsWords.replaceAll("[BANK_NAME]$", commonCodesGetComName("BANK_CODE", tsConfAccounts.bankCode));
      smsWords = smsWords.replaceAll("[DPSTR_AMT]$", numberWithCommas(data?.deducCompleteAmt ? data.deducCompleteAmt : commonCodesGetCommonCodeAttrbByCodeAndIdx("DPMNY_KIND", "RETURN", 1)) + "원");

      let rtnMessage = smsWords + "\n" + aditWords;

      return rtnMessage;
    },
    async onDepositMoneyIncomSmsSendButtonClicked() {
      try {
        const selectedRow = this.$refs.depositMoneyMembersGrid.getSelectedRecords();

        if (!(selectedRow.length > 0)) {
          this.infoToast("예치금 정보를 선택해 주시기 바랍니다");
          return;
        }

        if (selectedRow[0].dpmnyStatus !== "B1" && selectedRow[0].dpmnyStatus !== "C3") {
          this.infoToast('예치금 상태가 "반환형신청" 또는 "잔액부족"이 아닙니다');
          return;
        }

        const smsSendData = {
          smsSendType: "DPMNY_REG",
          messageContents: await this.makeSmsSendMessage(selectedRow[0]),
          rcverName: selectedRow[0].memberName,
          rcverInfo: selectedRow[0].hpNo,
          sendKey: "0",
        };

        this.isSmsSendPopupOpen = true;
        this.$nextTick(() => {
          this.$refs.smsSendPopup.showSmsSendPopup(smsSendData);
        });
      } catch (e) {
        console.error('e.===>', e);
        this.errorToast(e.message);
      }
    }
  },
};
</script>